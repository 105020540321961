export default {
  signIn: 'Sign In',
  signUp: 'Sign Up',
  welcomeBack: 'Welcome back! ',
  continue: 'Please login to continue',
  orEmail: 'or use your email and phone to sign in',
  orTel: 'or use your phone to sign in',
  emailPlaceholder: 'Your email address or phone',
  password: 'Password',
  forgotPsd: 'I forgot my password',
  needHelp: 'Need help signing in? ',
  contact: 'Contact support.',
  orEnterprise: 'Or Sign in with ',
  enterpriseLogin: 'Enterprise Login',
  started: 'Get started for free today',
  noCredit: 'No credit card required',
  getTvu: 'Gain access to most TVU services',
  name: 'Name',
  emailOrPhone: 'Signup with Email or by Phone',
  PsdPlaceholder: 'Password 8+ characters',
  agree: 'I agree to TVU Networks ',
  and: 'and',
  terms: 'Terms',
  privacy: 'Privacy Policy',
  startedNow: 'Get started now',
  signWith: 'or sign up with',
  singleSign: 'Enterprise Single Sign On',
  organization: 'Your organization uses Single Sign On (SSO) with',
  credentials: 'TVU. Please sign in using your SSO credentials.',
  companyPlaceholder: 'Your company domain name',
  login: 'Login',
  trouble: 'Having trouble logging in? ',
  accessRes: 'Request access',
  withoutSSO: 'Sign in without SSO',
  errorOkta: 'This company domain can\'t sign in with SSO.',
  ssoConfig: 'SSO Configuration?',
  checkService: 'Please check the service agreement',
  error80943102: 'Email address is already in use.Sign in directly',
  errorTel80943102: 'Phone is already in use.Sign in directly',
  error80943008: 'Sorry, we cannot provide service in this country.',
  error81000002: 'Invalid parameters.',
  checkPhone: 'Check your phone',
  checkEmail: 'Check your email',
  checkDes1: 'We\'ve sent you a six-digit confirmation code to ',
  checkDes2: 'Please enter it below to confirm your email address.',
  checkDes3: 'Please enter it below to confirm your account.',
  verify: 'Verify',
  sendCode: 'Send code again',
  findMore: 'or find more',
  information: 'information in',
  helpCenter: 'Help Center.',
  sendSuccess: 'Sent successfully',
  error80943201: 'Inexistent account',
  error80943202: 'VerifyCode error',
  error80943001: 'Login attempt failed',
  error80943003: 'This account hasn\'t been activated',
  error80943004: 'Sorry, you don\'t have access to this service',
  error80943009: 'The login attempt limit has been reached and the account has been locked for 2 hours.Or contact support@tvunetworks.com to unlock.',
  error80943010: ' attempts left.',
  emailTip: 'Please enter correct email format',
  nameTip: 'Please enter name',
  passwordTip: 'Please enter valid password',
  error80943601: 'Get verify code fail',
  forget: 'Forgot password?',
  forgetTip: 'Please enter the email address or phone you can receive.',
  email: 'Email',
  vcodePlaceholder: 'Type the text',
  vcodeEror: 'Incorrect verification code',
  next: 'Next',
  error80943701: 'Verify code expired.Please get verify code again',
  error80943702: 'Verify code error',
  resetPsw: 'Reset password',
  resetPswInfo: 'Please set your password of 8 to 64 characters.',
  pswtip: 'Passwords must contain alphabet upper and lower characters, numbers,symbols,and at least 8 digits',
  comfirmPsw: 'Confirm password',
  pswInconsist: 'Password not same as confirm password',
  done: 'Done',
  sendCodePhone: 'Send code',
  changeLang: 'Choose a language',
  tvuCommonGoogleLoginTip: {
    title: 'Please login with Google',
    name: 'Hi TVUer :',
    tips1: 'Your previous account will be logged out.',
    tips2: 'You must use “Login with Google” for access.',
    tips3: 'The services you previously logged in to will also use GG login.',
    shutdown: 'Automatic shutdown after',
    got: 'Got it'
  },
  error80945301: 'send verification code too frequently,please try again later',
  forgetAccount: 'Enter email or phone',
  enterTelTip: 'Enter correct phone number',
  errorPic80943701: 'Picture Verify code expired.Please get verify code again.',
  errorPic80943702: 'Picture verify code error.',
  error809434051: 'Too many validation errors, please try again in ',
  error809434052: ' minutes',
  verifyCodeTip: 'Please enter verification code',
  verifyPicCodeTip: 'Please enter the picture verification code',
  emailSignTip: 'Enter a valid email address',
  telSignTip: 'Enter a valid phone',
  emailOrTelSignTip: 'Enter a valid email address or phone',
  punctuation: '.',
  contactUs: 'Contact us',
  helpWay: 'If you need help signing in, please contact us in the following ways',
  invalidEmail: 'Email is invalid',
  accountUnlocked: 'Your account has been unlocked. It’s failed to reset password.',
  error80943504: 'This link has been expired',
  error80944603: 'Your sso configuration has not been accepted.Please contact to support',
  error80944601: 'Need to provide sso configuration',
  googleErrorTips: 'Google login resources failed to load. Please refresh the page and try again',
  ssoLoginTitle: 'Please Login using SSO',
  ssoLogin: 'Please Login to continue',
  ok: 'OK',
  signFail: 'sign in fail',
  bindPhone: 'Bind mobile',
  bindTitle: 'Binding mobile phone number',
  bindTips: 'To secure your account, please verify',
  bindPlaceholder: 'Please enter your mobile phone number',
  back: 'Back',
  wxDes: 'Scan to log in to WeChat',
  wxTvu: 'TVU Cloud Services',
  enterAccountTipCn: 'Please enter the correct email or mobile phone number',
  enterTip1Cn: 'Please enter email or phone',
  enterTip2Cn: 'Please enter the correct email or phone number',
  error80945302: 'This phone number has been bounden.Please change',
  deleteAccount: 'Delete TVU Account',
  secureAccount: 'To secure your account, please verify',
  accountPlaceholder: 'Please enter your email/phone number',
  passPlaceholder: 'Please enter your passcode',
  deleteButton: 'Delete your account',
  incorrectTips: 'Incorrect account or password.',
  confirmDelText: 'Are you sure you want to delete your TVU account? You will lose access to all TVU services.',
  delete: 'Delete',
  cancel: 'Cancel',
  deleteSuccess: 'Deleted successfully',
  deleteSuccessTip: 'Your account has been erased from the TVU database.',
  signInType: 'By signing in, ',
  signUpType: 'By signing up, ',
  agreeInfo: 'you agree to TVU Networks\'',
  termsInfo: 'terms of service',
  policyInfo: 'privacy policy.',
  otherEmailUse: 'Or use your email or phone to sign up'
}
