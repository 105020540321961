export default {
  signIn: '登录',
  signUp: '注册',
  welcomeBack: '欢迎回来！',
  continue: '请登录以继续使用',
  orEmail: '或使用您的电子邮件和电话进行登录',
  orTel: '或使用您的电话号码进行登录',
  emailPlaceholder: '邮箱或电话号码',
  password: '密码',
  forgotPsd: '忘记密码',
  needHelp: '需要帮助登录吗？',
  contact: '联系业务支持',
  orEnterprise: '或用企业单点',
  enterpriseLogin: '登录访问',
  started: '开始免费使用吧',
  noCredit: '不需要信用卡',
  getTvu: '获得大多数TVU服务的权限',
  name: '名字',
  emailOrPhone: '用电子邮件或电话注册',
  PsdPlaceholder: '请设置8位以上字符的密码',
  agree: '我同意TVU Networks的 ',
  and: '和',
  terms: '条款',
  privacy: '隐私政策',
  startedNow: '现在开始',
  signWith: '或通过以下方式注册',
  singleSign: '企业单点登录',
  organization: '如果您所在的公司在TVU使用了单点登录（SSO)，',
  credentials: '则可使用您的SSO凭证登录。',
  companyPlaceholder: '您的公司域名',
  login: '登录',
  trouble: '登录有问题？',
  accessRes: '请求访问',
  withoutSSO: '返回',
  errorOkta: '该公司域名不能用SSO登录。',
  ssoConfig: 'SSO配置？',
  checkService: '请勾选服务协议',
  error80943102: '邮箱地址已存在，请直接登录',
  errorTel80943102: '电话号码已存在，请直接登录',
  error80943008: '抱歉，我们无法在该国家/地区提供服务。',
  error81000002: '无效参数。',
  checkPhone: '验证您的手机号',
  checkEmail: '验证您的邮箱',
  checkDes1: '我们已经给您发送了一个六位数的验证码到',
  checkDes2: '请在下面输入以确认您的电子邮件地址。',
  checkDes3: '请在下面输入以确认您的电话。',
  verify: '验证',
  sendCode: '再次发送验证码',
  findMore: '或在',
  information: '帮助中心',
  helpCenter: '查找更多信息',
  sendSuccess: '发送成功',
  error80943201: '不存在的帐户',
  error80943202: '验证码错误',
  error80943001: '登录尝试失败',
  error80943003: '该账号尚未激活',
  error80943004: '抱歉，你暂无权限访问此服务',
  error80943009: '您的账号已达到登录尝试限制，账号已被锁定2小时。您可以联系support@tvunetworks.com解锁。',
  error80943010: '次尝试机会',
  emailTip: '请输入正确格式的邮箱',
  nameTip: '请输入用户名',
  passwordTip: '请输入有效密码',
  error80943601: '获取验证码失败',
  forget: '忘记密码?',
  forgetTip: '请输入您可以接收邮件的邮箱地址或者手机号码',
  email: '邮箱',
  vcodePlaceholder: '输入图形验证码',
  vcodeEror: '验证码错误',
  next: '下一步',
  error80943701: '验证码已过期，请重新获取验证码',
  error80943702: '验证码错误',
  resetPsw: '重置密码',
  resetPswInfo: '请设置8到64个字符的密码。',
  pswtip: '密码必须包含字母的大写和小写字母，数字，符号且至少8位',
  comfirmPsw: '二次确认密码',
  pswInconsist: '两次密码输入需保持一致',
  done: '完成',
  sendCodePhone: '发送验证码',
  changeLang: '选择语言',
  tvuCommonGoogleLoginTip: {
    title: '请用谷歌登录',
    name: '你好，TVU用户：',
    tips1: '您之前的账号将被退出',
    tips2: '然后请使用 "谷歌“账号登录',
    tips3: '在此之前您登录的服务，也将用谷歌账号继续使用。',
    shutdown: '秒后自动关闭',
    got: '知道了'
  },
  enterTelTip: '请输入正确的手机号码',
  error80945301: '发送验证码太频繁，请稍后再试',
  forgetAccount: '输入邮箱或手机号码',
  errorPic80943701: '图片验证码已过期，请重新获取验证码',
  errorPic80943702: '图片验证码错误',
  error809434051: '验证错误次数太多，请在',
  error809434052: '分钟后重试',
  verifyCodeTip: '请输入验证码',
  verifyPicCodeTip: '请输入图片验证码',
  emailSignTip: '请输入有效的邮箱地址',
  telSignTip: '请输入有效的电话号码',
  emailOrTelSignTip: '请输入有效的邮箱地址或电话号码',
  punctuation: '，',
  contactUs: '联系我们',
  helpWay: '如果您需要登录帮助，请通过以下方式与我们联系',
  invalidEmail: '电子邮件无效',
  accountUnlocked: '你的账号已解锁。重置密码失败。',
  error80943504: '此链接已过期',
  error80944603: '您的 sso 配置未被接受。请联系support',
  error80944601: '需要提供sso配置',
  googleErrorTips: '谷歌登录资源加载失败，请刷新页面后重试',
  ssoLoginTitle: '请使用SSO登录',
  ssoLogin: '请继续登录',
  ok: '好的',
  signFail: '登录失败',
  bindPhone: '绑定手机',
  bindTitle: '手机号绑定',
  bindTips: '为了保障你的账号安全，请进行验证',
  bindPlaceholder: '请输入要绑定的手机号码',
  back: '返回',
  wxDes: '使用微信扫一扫登录',
  wxTvu: 'TVU 云服务',
  enterAccountTipCn: '请输入正确的邮箱或者手机号码',
  enterTip1Cn: '请输入电子邮件或电话',
  enterTip2Cn: '请输入正确的电子邮件或电话',
  error80945302: '此电话号码已被绑定，请更改',
  deleteAccount: '删除TVU帐户',
  secureAccount: '为了确保您的帐户安全，请验证',
  accountPlaceholder: '请输入您的电子邮件/电话号码',
  passPlaceholder: '请输入您的密码',
  deleteButton: '删除您的帐户',
  incorrectTips: '帐号或密码错误',
  confirmDelText: '您确定要删除您的TVU帐户吗？您将无法访问所有TVU服务。',
  delete: '删除',
  cancel: '取消',
  deleteSuccess: '删除成功',
  deleteSuccessTip: '您的帐户已从TVU数据库中删除。',
  signInType: '登录',
  signUpType: '注册',
  agreeInfo: '即表示您同意TVU Networks的',
  termsInfo: '服务条款',
  policyInfo: '隐私政策.',
  otherEmailUse: '或使用您的电子邮件和电话进行注册'
}
